import { Site } from "../interfaces/api-interfaces";
import { APiBaseService } from "./api-base.service";

export class SiteService extends APiBaseService {
  constructor() {
    super();
    this.baseUrl = `${process.env.REACT_APP_API_URL}/site`;
  }
  find(clientId: number): Promise<Site[]> {
    const url = this.baseUrl;
    return this.get<Site[]>(`${url}/v2/?clientId=${clientId}`);
  }
}
