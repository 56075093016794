import { FormattedMessage } from "react-intl";

import { Typography, TypographyProps } from "@mui/material";

type TextProps = TypographyProps & {
  text: string;
  format?: boolean;
};

/**
 * Display formatted text with Typography
 * @param text string
 * @param format boolean (default: true)
 * @param ...props TypographyProps
 *
 */
export function Text({ text, format = false, ...props }: TextProps) {
  return (
    <Typography {...props}>
      {format ? <FormattedMessage id={text} /> : text}
    </Typography>
  );
}
