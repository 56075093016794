import { RppsDto } from "../interfaces/api-interfaces";
import { APiBaseService } from "./api-base.service";

export class DoctorService extends APiBaseService {
  constructor() {
    super();
    this.baseUrl = `${process.env.REACT_APP_API_URL}/doctor`;
  }

  async autocompleteByName(
    name: string,
    page?: number,
    limit?: number
  ): Promise<RppsDto[]> {
    const url = `${process.env.REACT_APP_API_URL}/directory/autocomplete/name`;
    return await this.get<RppsDto[]>(
      `${url}`,
      {
        name,
        page: page || 0,
        limit: limit || 20,
      },
      { "x-api-key": process.env.REACT_APP_API_KEY || "" }
    );
  }
}
