import { QuestionDto } from "../interfaces/api-interfaces";
import { APiBaseService } from "./api-base.service";

export class QuestionService extends APiBaseService {
  constructor() {
    super();
    this.baseUrl = `${process.env.REACT_APP_API_URL}/questions`;
  }
  find(examId: number): Promise<QuestionDto[]> {
    const url = this.baseUrl;
    return this.get<QuestionDto[]>(`${url}?examId=${examId}`);
  }
}
