import { Delete } from '@mui/icons-material';
import { Button, Grid, Tooltip, Typography } from '@mui/material';

interface Props {
	file: File;
	onDelete: (item: any) => void;
}

export const FileItem = ({ file, onDelete }: Props) => {
	const handleDelete = () => {
		onDelete && onDelete(file);
	};

	return (
		<Grid container alignItems="center" justifyContent="flex-start">
			<Grid item xs={2}>
				<Typography variant="body1" component="body" style={{ fontSize: '12px', color: 'grey' }}>
					{file.name}
				</Typography>
			</Grid>
			<Tooltip title="Supprimer" placement="right">
				<Button onClick={handleDelete} size="small" color="secondary">
					<Delete fontSize="small" />
				</Button>
			</Tooltip>
		</Grid>
	);
};
